import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import { Button } from "../Common/Button";
import { useRouter } from "next/router";
import SelectLanguage from "@/components/Chat/SelectLanguage";
import { useTranslation } from "react-i18next";
import { handleGetStartedClick } from "lib/getStartedUserClick";
import GuestSelect from "@/components/GuestSelect";
import { ROUTES } from "@/constants/routes";

export default function Navbar() {
  const router = useRouter();
  const { colorMode } = useColorMode();
  const { t } = useTranslation("translation");

  const [navbar, setNavbar] = useState(false);
  const [hoveredItem, setHoveredItem] = useState("");
  const [language, setLanguage] = useState(0);
  const [maxWidthClass, setMaxWidthClass] = useState("max-w-[1152px]");
  const widgets = [
    {
      id: 0,
      title: t("dropdown_img"),
      value: "photo_generation",
    },
    {
      id: 1,
      title: t("dropdown_spech"),
      value: "text_to_speech",
    },
    {
      id: 2,
      title: t("dropdown_docTrans"),
      value: "doc_translation",
    },
    {
      id: 3,
      title: t("dropdown_doc"),
      value: "chat_with_doc",
    },
    {
      id: 4,
      title: t("dropdown_trans"),
      value: "transcription",
    },
    {
      id: 5,
      title: t("dropdown_model"),
      value: "Loki Profiles",
    },
  ];
  const [widget, setWidget] = useState(widgets[0]);

  const Navigation = [
    {
      name: t("features"),
      href: "/features",
    },
    // {
    //   name: t("howIt_works"),
    //   href: "/how-it-works",
    // },
    {
      name: t("pricing"),
      href: "/pricing-home",
    },
    {
      name: t("download"),
      href: "/download",
    },
    {
      name: t("affiliate_program"),
      href: "/affiliate-program",
    },
    {
      name: t("contact"),
      href: "/contact",
    },
  ];

  useEffect(() => {
    if (language == 2 || language == 5 || language == 7 || language == 8) {
      setMaxWidthClass("w-[1280px]");
    } else {
      setMaxWidthClass("w-[1152px]");
    }
  }, [language, t]);

  const handleStartClick = () => {
    handleGetStartedClick(router);
  };

  const onSelectFeature = (item: any) => {
    router.push(ROUTES.FEATURES + "/" + item?.value);
    setHoveredItem('')
  };

  return (
    <header className="Header" id="Header">
      <div className={`${maxWidthClass} m-auto px-4`}>
        <nav className="lxl:gap-4 gap-8 llg:flex llg:items-center py-6">
          <div className="lxl:pr-12 llg:pr-6 llg:border-r border-gray-50 flex items-center justify-between py-3 llg:block llg:py-2">
            <Link href="/">
              <figure>
                <Image
                  src={
                    colorMode === "dark"
                      ? "https://lokichatdev.blob.core.windows.net/images/loki/logo.svg"
                      : "https://lokichatdev.blob.core.windows.net/images/loki/logow.svg"
                  }
                  height={32}
                  width={108}
                  alt="logo"
                />
              </figure>
            </Link>
            <div className="llg:hidden">
              <button
                className="rounded-md p-1.5 text-gray-700 outline-none focus:border focus:border-gray-400"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 dark:text-white text-text-gray-50"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 dark:text-white text-gray-50"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div
            className={`llg:overflow-visible duration-300 ease-linear llg:flex w-full justify-between ${
              navbar
                ? "h-auto pt-2 llg:h-auto llg:pb-0 llg:pt-0"
                : "mt-0 h-0 llg:h-auto overflow-hidden"
            }`}
            onMouseLeave={() => setHoveredItem("")}
          >
            <ul className="llg:items-center items-start llg:justify-center llg:flex-row flex-col flex lxl:gap-10 llg:space-x-4 llg:gap-0 gap-6 llg:mb-0 mb-6 px-2">
              {Navigation &&
                Navigation.map((item, i) => (
                  <li
                    key={i}
                    onMouseEnter={() => setHoveredItem(item.name)}
                    className="relative"
                  >
                    <Link
                      href={item.href === "/features" ? "" : item.href}
                      className={`${
                        item.href === router.pathname
                          ? "dark:text-white text-black"
                          : "text-gray-50 hover:text-black dark:hover:text-white"
                      } llg:text-sm text-xs font-extrabold font-mont`}
                    >
                      {item.name}
                    </Link>
                    {item.name === t("features") &&
                      hoveredItem === t("features") && (
                        <div className="absolute top-full left-0 mt-2 z-10 w-[250px]">
                          <GuestSelect
                            classButton="bg-white rounded-t-lg border-b rounded-b-none  dark:bg-n-5 dark:text-n-6"
                            title={"Select Feature"}
                            items={widgets}
                            value={widget}
                            fromHome={true}
                            onChange={onSelectFeature}
                            arrowColor={"black"}
                          />
                        </div>
                      )}
                  </li>
                ))}
            </ul>
            <div className="flex llg:flex-row flex-col gap-6 llg:items-center items-start">
              <SelectLanguage
                className="llg:!right-0 -right-[231px]"
                setLanguage={setLanguage}
              />
              <Button
                variant="solid"
                onClick={handleStartClick}
                className="flex-shrink-0 cursor-pointer font-mont lg:text-sm"
              >
                {t("coming_soon")}
              </Button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
