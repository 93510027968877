
export const LandingRoutes=[
   "/",
   "/home",
   "/features",
   // "/how-it-works",
   "/pricing-home",
   "/contact",
   "/download",
   "/legal-notice",
   "/privacy-policy",
   "/cookies-policy",
   "/affiliate-program"
]