import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Button } from "../Common/Button";
import { useColorMode } from "@chakra-ui/color-mode";
import LandingLightDark from "@/components/LandingLightDark";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@/constants/routes";
import { handleGetStartedClick } from "lib/getStartedUserClick";
import { useRouter } from "next/router";
import Icon from "@/components/Icon";

const Footer = () => {
  const router = useRouter();
  const { t } = useTranslation("translation");
  const { colorMode } = useColorMode();

  const handleStartClick = () => {
    handleGetStartedClick(router);
  };

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  return (
    <footer className="Footer dark:bg-[#1F2328]" id="Footer">
      <div className="border-n-3 dark:border-n-5 border-t border-b">
        <div className="container grid llg:grid-cols-3 lsm:grid-cols-2 justify-center">
          <div className=" flex lsm:flex-row flex-col gap-7 lsm:items-start items-center lsm:gap-12 llg:pt-20 pt-10 llg:pb-14 lsm:pb-10 lsm:px-5 llg:px-0">
            <Link href="/">
              <figure className="">
                <Image
                  src={
                    colorMode == "dark"
                      ? "https://lokichatdev.blob.core.windows.net/images/loki/logo.svg"
                      : "https://lokichatdev.blob.core.windows.net/images/loki/logow.svg"
                  }
                  height={32}
                  width={108}
                  alt={"logo"}
                />
              </figure>
            </Link>
            <ul className="text-base font-mont font-medium flex lsm:flex-col flex-row lsm:justify-start justify-center flex-wrap gap-6">
              <li className="text-gray-50 dark:hover:text-white hover:text-black font-extrabold text-sm">
                <Link href={"/features"}>{t("features")}</Link>
              </li>
              {/* <li className="text-gray-50 dark:hover:text-white hover:text-black font-extrabold text-sm">
                <Link href={"/how-it-works"}>{t("howIt_works")}</Link>
              </li> */}
              <li className="text-gray-50 dark:hover:text-white hover:text-black font-extrabold text-sm">
                <Link href={"/pricing-home"}>{t("pricing")}</Link>
              </li>
              <li className="text-gray-50 dark:hover:text-white hover:text-black font-extrabold text-sm">
                <Link href={"/download"}>{t("download")}</Link>
              </li>
              <li className="text-gray-50 dark:hover:text-white hover:text-black font-extrabold text-sm">
                <Link href={"/affiliate-program"}>{t("affiliate_program")}</Link>
              </li>
              <li className="text-gray-50 dark:hover:text-white hover:text-black font-extrabold text-sm">
                <Link href={"/contact"}>{t("contact")}</Link>
              </li>
            </ul>
          </div>
          <div className="llg:border-n-3 llg:dark:border-n-5 border-transparent llg:pt-20 pt-10 llg:pb-14 lsm:pb-10 pb-5 border-l border-r lsm:px-5 llg:px-10">
            <p className="text-base dark:text-white text-black font-bold font-mont llg:mb-7 mb-5">
              LOKI CHAT
            </p>
            <p className="text-sm dark:text-white text-black font-mont mb-6">
              {t("loki_footerDesc")}
            </p>
            <Button
              onClick={handleStartClick}
              className="!px-10 cursor-pointer font-mont"
            >
              {t("coming_soon")}
            </Button>
          </div>
          <div className="llg:pt-20 lmd:pt-10 pt-5 llg:pb-14 lsm:pb-10 pb-5 lsm:pl-5 llg:pl-10">
            <p className="text-base dark:text-white text-black font-bold font-mont llg:mb-7 mb-5">
              {t("FOLLOW US ON")}
            </p>
            {/* <p className='text-sm dark:text-white text-black font-mont mb-6'>{t('referal_programDesc')}</p> */}
            <div className="flex gap-4 mb-8">
              <div className="flex gap-4">
                <a
                  href="https://www.linkedin.com/company/lokichat/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure className="w-[46px] h-[46px] flex justify-center items-center cursor-pointer bg-transparent duration-1000">
                    <Image
                      src={"https://storage.googleapis.com/lokichat-profile/loki_footer_linkedin1.png"}
                      alt={"LinkedIn"}
                      height={45}
                      width={45}
                    />
                  </figure>
                </a>

                <a
                  href="https://www.tiktok.com/@_lokichat"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure className="w-[46px] h-[46px] flex justify-center items-center cursor-pointer bg-transparent duration-1000">
                    <Image
                      src={"https://storage.googleapis.com/lokichat-profile/loki_footer_tiktok1.png"}
                      alt={"TikTok"}
                      height={45}
                      width={45}
                    />
                  </figure>
                </a>

                <a
                  href="https://www.youtube.com/@_lokichat"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure className="w-[45px] h-[45px] flex justify-center items-center cursor-pointer bg-transparent duration-1000">
                    <Image
                      src={"https://storage.googleapis.com/lokichat-profile/loki_footer_youtube1.png"}
                      alt={"YouTube"}
                      height={45}
                      width={45}
                    />
                  </figure>
                </a>

                <a
                  href="https://www.instagram.com/_lokichat/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure className="w-[45px] h-[45px] flex justify-center items-center cursor-pointer bg-transparent  duration-1000">
                    <Image
                      src={"https://storage.googleapis.com/lokichat-profile/loki_footer_insta1.png"}
                      alt={"Instagram"}
                      height={45}
                      width={45}
                    />
                  </figure>
                </a>
              </div>
            </div>
            <LandingLightDark />
            <div className=" text-xs mt-5 flex justify-end items-center">
              Back to Top{" "}
              <button
                onClick={scrollToTop}
                className=" bg-primary-1/90 p-1 rounded-full ml-3"
              >
                <Icon name={"charvonUp"} className=" fill-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 flex gap-5 flex-wrap justify-between container">
        <p className="text-sm font-medium font-mont text-gray-50">
          {t("copyright_loki")}
        </p>
        <div className="flex flex-wrap gap-8 md:gap-4">
          <Link
            href={ROUTES.LEGAL_NOTICE}
            target="_blank"
            className="text-sm font-medium font-mont text-gray-50"
          >
            {t("legal_notice")}
          </Link>
          <Link
            href={ROUTES.PRIVACY_POLICY}
            target="_blank"
            className="text-sm font-medium font-mont text-gray-50"
          >
            {t("privacy_policy")}
          </Link>
          <Link
            href={ROUTES.COOKIES_POLICY}
            target="_blank"
            className="text-sm font-medium font-mont text-gray-50"
          >
            {t("cookies_policy")}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
